<script setup lang="ts">
import { formatTimeAgo } from '@vueuse/core'

const { isNotificationsSlideoverOpen } = useDashboard()

const { data: notifications, refresh, status } = await useAPI<Notification[]>('/notificacoes', {
	method: 'GET',
	immediate: true
})
</script>

<template>
	<UDashboardSlideover
		v-model="isNotificationsSlideoverOpen"
	>
		<template #title>
			<h2>Notificações</h2>
			<UTooltip text="Atualizar">
				<UIcon
					name="i-heroicons-arrow-path"
					class="cursor-pointer transition-transform ml-2"
					:class="{ 'animate-spin': status === 'pending' }"
					@click="refresh"
				/>
			</UTooltip>
		</template>
		<template v-if="status === 'pending'">
			<div
				v-for="i in 3"
				:key="i"
				class="p-3 rounded-md animate-pulse"
			>
				<div class="flex items-center gap-3">
					<div class="flex-1">
						<div class="h-3 w-20 bg-gray-200 dark:bg-gray-700 rounded mb-2" />
						<div class="h-4 w-full bg-gray-200 dark:bg-gray-700 rounded" />
					</div>
				</div>
			</div>
		</template>

		<template v-else-if="!notifications?.length">
			<UAlert
				title="Nenhuma notificação no momento..."
				icon="i-heroicons-bell"
			/>
		</template>

		<template v-else>
			<NuxtLink
				v-for="notification in notifications"
				:key="notification.id"
				:to="`/inbox?id=${notification.id}`"
				class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative"
			>
				<div class="text-sm flex-1">
					<p class="flex items-center justify-between">
						<time
							:datetime="notification.data"
							class="text-gray-500 dark:text-gray-400 text-xs"
							v-text="formatTimeAgo(new Date(notification.data))"
						/>
					</p>
					<p class="text-gray-500 dark:text-gray-400">
						{{ notification.mensagem }}
					</p>
				</div>
			</NuxtLink>
		</template>
	</UDashboardSlideover>
</template>
